import React from "react";

function AboutUs() {
  return (
    <section id="about-us" className="scroll-smooth">
      <div className="flex justify-center flex-col items-center ">
        <div className="relative  mt-3">
          <div className="flex flex-row">
            <div className="border-2 border-y-[60px] absolute -top-12 -left-20 lg:-top-11 lg:-left-20 rotate-90 border-[#28256D]"></div>
            <div className="text-[16px] text-[#28256D] md:text-xl">
              OUR STORY
            </div>
            <div className="border-2 border-y-[60px] absolute -top-12 -right-20 lg:-top-11 lg:-right-20 rotate-90 border-[#28256D]"></div>
          </div>
        </div>
        <div className="relative top-2 mb-5">About 3 Point 3</div>
        <div className="flex text-sm md:text-lg flex-col w-[60%] justify-center items-center">
          <p>
            We are a team of highly skilled and dedicated professionals who are
            passionate about providing cutting-edge technological solutions to
            our clients.
          </p>
          <br />
          <p>
            Our company was founded with a vision to make technology work for
            businesses and individuals alike, and we have been achieving this
            goal since our inception.
          </p>
          <br />
          <p>
            At 3 Point 3, we believe that technology can revolutionize the way
            businesses operate, and we are committed to helping our clients
            leverage the latest technological advances to stay ahead of the
            competition. We offer a wide range of IT services, including
            software development, website design and development, cloud
            computing solutions, and more.
          </p>
          <br />
          <p>
            Our team is made up of experienced and talented developers,
            designers, and IT specialists who have a deep understanding of the
            latest technologies and trends. We work closely with our clients to
            understand their specific needs and provide tailored solutions that
            meet their unique requirements.
          </p>
          <br />
          <p>
            We pride ourselves on our customer-centric approach, and we go above
            and beyond to ensure that our clients are completely satisfied with
            our services. We believe in building long-term relationships with
            our clients and helping them achieve their business objectives.
          </p>
          <br />
        </div>
      </div>
    </section>
  );
}

export default AboutUs;
