import React from "react";
import logo from "./final.jpg";
import MenuItems from "./MenuItems";
import Sidebar from "./Sidebar";

function Header() {
  const menuItems = [
    {
      id: 1,
      title: "Home",
      url: "#/",
    },
    {
      id: 2,
      title: "About Us",
      url: "#about-us",
    },
    {
      title: "Services",
      url: "#services",
    },
    // {
    //   title: "Testimonials",
    //   url: "#testimonials",
    // },
    {
      id: 3,
      title: "Contact Us",
      url: "#contact-us",
    },
  ];

  return (
    <div className="bg-white border-b-2 shadow-md flex h-16 w-full justify-around">
      {/* Logo Section */}
      <div className="flex items-center justify-center left-12 md:ml-10 p-2">
        <a href="#" className="flex">
          <img src={logo} className="w-auto h-[30px]" alt="" />
        </a>
      </div>

      {/* Menu Section */}
      <div className="flex justify-center items-center">
        <ul className="hidden md:flex space-x-5 -mr-50">
          {menuItems.map((item, i) => {
            const list = <MenuItems menu={item} />;
            return list;
          })}
        </ul>
        <Sidebar listItems={menuItems} />
      </div>
    </div>
  );
}

export default Header;
