import React from "react";

function Service({ Icon, title, description }) {
  return (
    <div
      className="bg-[#043a9c] w-[420px] mr-10 ml-10 rounded-xl flex flex-col justify-center items-center text-white
              p-5 mb-10 hover:shadow-2xl transform 
              transition duration-300 hover:scale-110"
    >
      <Icon class="text-2xl md:text-4xl" />
      <div className="font-bold">{title}</div>
      <div>{description}</div>
    </div>
  );
}

export default Service;
