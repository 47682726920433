import { useState } from "react";
import { AiOutlineMenu } from "react-icons/ai";

const Sidebar = ({ listItems }) => {
  const [showSidebar, setShowSidebar] = useState(false);

  return (
    <>
      <AiOutlineMenu
        onClick={() => setShowSidebar(!showSidebar)}
        className="md:hidden cursor-pointer relative left-12"
      />

      <div
        className={`top-0 -left-0 w-[40vw] bg-gray-100  p-10 pl-20 text-white fixed h-full z-40  ease-in-out duration-300 ${
          showSidebar ? "-translate-x-0 " : "-translate-x-full"
        }`}
      >
        <button
          className="flex text-2xl text-[#043a9c] items-center cursor-pointer fixed left-40 -top-0 z-50"
          onClick={() => setShowSidebar(!showSidebar)}
        >
          x
        </button>
        <ul className="absolute right-16 space-y-8">
          {console.log({ listItems })}
          {listItems.map((item, i) => {
            const list = (
              <li key={i}>
                <a
                  href={item.url}
                  onClick={() => setShowSidebar(!showSidebar)}
                  className="text-[#043a9c]
                     text-[14px] hover:text-[#043a9c]/50 p-5 divide-x-2"
                >
                  {item.title}
                </a>
              </li>
            );
            return list;
          })}
        </ul>
      </div>
    </>
  );
};

export default Sidebar;
