import React from "react";
import bgImage from "./bgImage.png";
import { BsFillArrowRightCircleFill } from "react-icons/bs";

function TopSection() {
  return (
    <div className="w-full h-screen flex justify-center items-center mb-10">
      <div class="bg-black opacity-80 w-full rounded-b-[100%] absolute h-[100vh]" />
      <img
        src={bgImage}
        className="object-cover w-full rounded-b-[100%] h-full"
        alt=""
      />
      <div className="absolute top-[25%] ] mt-3">
        <div className="flex flex-col justify-center items-center">
          <div className="text-[12px] md:font-bold mt-3 text-white">
            WELCOME TO 3 POINT 3
          </div>
          <div className="text-[16px] text-white lg:text-3xl xl:text-3xl 2xl:text-3xl pt-2">
            WE DESIGN, CODE AND DELIVER
          </div>
          <div className="flex flex-row">
            <div className="border-2 border-y-[60px] absolute top-4 left-14 lg:top-8 lg:left-20 rotate-90 border-white"></div>
            <div className="text-[16px] text-white lg:text-3xl xl:text-3xl 2xl:text-3xl">
              YOUR DIGITAL VISION
            </div>
            <div className="border-2 border-y-[60px] absolute top-4 right-14 lg:top-8 lg:right-20 rotate-90 border-white"></div>
          </div>
        </div>
        <div className="pt-10 text-white text-[12px] lg:text-lg flex flex-col justify-center items-center">
          <div>
            At 3 Point 3 we work with our clients to carefully balance their
            technology
          </div>
          <div>
            investments with their business objects, to ensure that we provide
            solutions that are
          </div>
          <div>effective and within budget.</div>
          <div>
            <button
              class="bg-[#043a9c] hover:bg-[#043a9c]/80 text-white font-semibold flex justify-center items-center
               py-2 px-4 mt-5 rounded"
            >
              <div className="px-2">Connect With Us</div>
              <BsFillArrowRightCircleFill className="text-white" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopSection;
