import React from "react";
import { RiComputerLine } from "react-icons/ri";
import { VscServerProcess } from "react-icons/vsc";
import { BsFillDatabaseFill, BsFillCloudArrowDownFill } from "react-icons/bs";
import Service from "./Service";

function Services() {
  return (
    <section id="services" className="scroll-smooth">
      <div className="flex justify-center flex-col items-center">
        <div className="relative mt-3">
          <div className="flex flex-row">
            <div className="border-2 border-y-[60px] absolute -top-12 -left-20 lg:-top-11 lg:-left-20 rotate-90 border-[#28256D]"></div>
            <div className="text-[16px] text-[#28256D] md:text-xl">
              OUR SERVICES
            </div>
            <div className="border-2 border-y-[60px] absolute -top-12 -right-20 lg:-top-11 lg:-right-20 rotate-90 border-[#28256D]"></div>
          </div>
        </div>
        <div className="mb-5">Services that we provide</div>
        <div className="flex flex-row justify-center items-center">
          <div className="grid grid-cols-1 lg:grid-cols-2">
            <Service
              Icon={RiComputerLine}
              title="Web Development"
              description="Our team of experienced developers specialize in creating custom websites that are optimized for performance, functionality, and user experience. We use the latest technologies and frameworks to ensure that your website is fast, secure, and responsive across all devices."
            />
            <Service
              Icon={VscServerProcess}
              title="Back-end Development"
              description="At 3 Point 3, we take a comprehensive approach to back-end development, ensuring that your web application is designed and developed to meet your unique business needs. Contact us today to learn more about how we can help with your back-end development requirements."
            />
            <Service
              Icon={BsFillDatabaseFill}
              title="Data Analytics"
              description="At 3 Point 3, we also provide data analytics services to help businesses make informed decisions based on their data. Our team of data analysts can help you collect, process, analyze, and visualize your data to gain insights and optimize your business operations."
            />
            <Service
              Icon={BsFillCloudArrowDownFill}
              title="Cloud Computing"
              description="At 3 Point 3, we also provide cloud computing services to help businesses optimize their digital infrastructure. Our team of cloud experts can help you migrate your applications and systems to the cloud, as well as provide ongoing support and management."
            />
            <Service
              Icon={BsFillCloudArrowDownFill}
              title="Outsourced IT services"
              description="At 3 Point 3, we offer outsourced IT services to help businesses reduce their operational costs, improve their productivity, and focus on their core competencies. Our team of IT professionals can provide comprehensive IT services that align with your business goals and requirements."
            />
            {/* <div
            className="bg-[#043a9c] rounded-xl flex flex-col justify-center items-center text-white
                        p-5 mb-10 ml-10 transform 
                        transition duration-300 hover:scale-110"
          >
            <VscServerProcess className="text-[30px]" />
            <div>Back-end Development</div>
            <div>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime
              mollitia,molestiae quas vel sint commodi repudiandae consequuntur
              voluptatum laborum
            </div>
          </div>
          <div
            className="bg-[#043a9c] w-[420px] rounded-xl flex flex-col justify-center items-center text-white
                        p-5 mb-10 transform 
                        transition duration-300 hover:scale-110"
          >
            <BsFillDatabaseFill className="text-[30px]" />
            <div>Data Analytics</div>
            <div>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime
              mollitia,molestiae quas vel sint commodi repudiandae consequuntur
              voluptatum laborum
            </div>
          </div>
          <div
            className="bg-[#043a9c] w-[420px] rounded-xl flex flex-col justify-center items-center text-white
                        p-5 mb-10 ml-10 transform 
                        transition duration-300 hover:scale-110"
          >
            <BsFillCloudArrowDownFill className="text-[30px]" />
            <div>Cloud Computing</div>
            <div>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime
              mollitia,molestiae quas vel sint commodi repudiandae consequuntur
              voluptatum laborum
            </div>
          </div>
          <div
            className="bg-[#043a9c] w-[420px] rounded-xl flex flex-col justify-center items-center text-white
                        p-5 mb-10 ml-10 transform 
                        transition duration-300 hover:scale-110"
          >
            <BsFillCloudArrowDownFill className="text-[30px]" />
            <div>Outsourced IT services</div>
            <div>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime
              mollitia,molestiae quas vel sint commodi repudiandae consequuntur
              voluptatum laborum
            </div>
          </div> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;
