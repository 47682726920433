import React from "react";
import { useForm } from "react-hook-form";
import { BsFillArrowRightCircleFill } from "react-icons/bs";

function ContactUs() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => console.log(data);

  // function sendEmail(e) {
  //   e.preventDefault(); //This is important, i'm not sure why, but the email won't send without it

  //   emailjs
  //     .sendForm("YOUR_SERVICE_ID", "YOUR_TEMPLATE_ID", e.target, "YOUR_USER_ID")
  //     .then(
  //       (result) => {
  //         window.location.reload(); //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior)
  //       },
  //       (error) => {
  //         console.log(error.text);
  //       }
  //     );
  // }

  return (
    <section id="contact-us" className="scroll-smooth">
      <div className="flex justify-center flex-col items-center">
        <div className="relative mt-3">
          <div className="flex flex-row">
            <div className="border-2 border-y-[60px] absolute -top-12 -left-20 lg:-top-11 lg:-left-20 rotate-90 border-[#28256D]"></div>
            <div className="text-[16px] text-[#28256D] md:text-xl">
              CONTACT US
            </div>
            <div className="border-2 border-y-[60px] absolute -top-12 -right-20 lg:-top-11 lg:-right-20 rotate-90 border-[#28256D]"></div>
          </div>
        </div>
        <div className="top-2 mb-5">Your can contact us</div>
        <form className="w-full max-w-2xl md:max-w-2xl pt-10">
          <div className="grid grid-cols-1 ">
            <div className="w-full px-3 mb-4">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-full-name"
              >
                Full Name
              </label>
              <input
                className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight 
                focus:outline-none focus:bg-white focus:border focus:border-[#28256D]"
                id="grid-full-name"
                type="text"
                placeholder="Full Name"
              />
            </div>
            <div className="w-full px-3 mb-4">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Email Address
              </label>
              <input
                className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white
              focus:border focus:border-[#28256D]"
                id="grid-email"
                type="text"
                placeholder="Email Address"
              />
            </div>
            <div className="w-full px-3 mb-4">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-phone-number"
              >
                Phone Number
              </label>
              <input
                className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none
                 focus:bg-white focus:border focus:border-[#28256D]"
                id="grid-phone-number"
                type="text"
                placeholder="Phone Number"
              />
            </div>
            <div className="w-full px-3 mb-4">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-thought"
              >
                Please share your thoughts
              </label>
              <textarea
                className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white
              focus:border focus:border-[#28256D]"
                id="grid-thought"
              />
              <p className="text-gray-600 text-xs italic">
                Make it as long and as crazy as you'd like
              </p>
            </div>

            <div className="flex justify-center items-center">
              <button
                class="bg-[#043a9c] hover:bg-[#043a9c]/80 text-white font-semibold flex justify-center items-center
               py-2 px-4 mt-5 rounded"
              >
                <div className="px-2">Connect With Us</div>
                <BsFillArrowRightCircleFill className="text-white" />
              </button>
            </div>
          </div>
        </form>

        {/* <form onSubmit={handleSubmit(onSubmit)}>
        {/* register your input into the hook by invoking the "register" function */}
        {/* <input defaultValue="test" {...register("example")} /> */}
        {/* include validation with required or other standard HTML validation rules */}
        {/* <input {...register("exampleRequired", { required: true })} /> */}
        {/* errors will return when field validation fails  */}
        {/* {errors.exampleRequired && <span>This field is required</span>}
        <input type="submit" /> */}
        {/* </form> */}
        <br />
        <br />
      </div>
    </section>
  );
}

export default ContactUs;
