import React from "react";

function MenuItems({ menu }) {
  return (
    <div>
      <li className="text-sm">
        <a
          href={menu.url}
          className="text-[#28256D] hover:bg-[#28256D]
                    text-[14px] hover:text-white hover:rounded-lg p-5 hover:shadow-lg divide-x-2"
        >
          {menu.title}
        </a>
      </li>
    </div>
  );
}

export default MenuItems;
