import React from "react";
import bgImage from "./bgImage.png";
import { BsFillArrowRightCircleFill, BsTwitter } from "react-icons/bs";
import { FaFacebookF, FaLinkedinIn, FaPhoneVolume } from "react-icons/fa";
import { MdEmail, MdLocationOn } from "react-icons/md";

function Footer() {
  return (
    <div className="w-full h-[50vh] flex justify-center items-center">
      <div class="bg-black opacity-80 w-full absolute h-[50vh]" />
      <img src={bgImage} className="object-cover w-full h-[50vh]" alt="" />
      <div className="absolute">
        <div className="flex flex-row space-x-4 justify-center items-center">
          <h6 className="text-[14px] md:text-[16px]">Follow Us:</h6>
          <div className="bg-white w-6 h-6 md:w-8 md:h-8 rounded-full flex justify-center items-center animate-bounce">
            <a href="#">
              <FaFacebookF className="text-[#4267B2] text-sm md:text-lg" />
            </a>
          </div>
          <div className="bg-white w-6 h-6 md:w-8 md:h-8 rounded-full flex justify-center items-center animate-bounce">
            <a href="#">
              <BsTwitter className="text-[#1DA1F2] text-sm md:text-lg" />
            </a>
          </div>
          <div className="bg-white w-6 h-6 md:w-8 md:h-8 rounded-full flex justify-center items-center animate-bounce">
            <a href="#">
              <FaLinkedinIn className="text-[#2867B2] text-sm md:text-lg" />
            </a>
          </div>
          <div className="pl-10">
            <p className="text-white text-[14px] md:text-[16px]">
              Connect with Us
            </p>
            <div className="flex flex-col justify-between">
              <div className="w-full flex flex-row pt-3">
                <div className="w-6 h-6 md:w-8 md:h-8 rounded-full bg-[#043a9c] flex justify-center items-center">
                  <FaPhoneVolume className="text-white text-sm md:text-lg" />
                </div>
                <div className="text-white pl-3 text-[12px] md:text-[16px]">
                  <p>Call Us:</p>
                  <p>065 977 5729</p>
                </div>
              </div>
              <div className="w-full flex flex-row pt-3 text-[12px] md:text-[16px]">
                <div className="w-6 h-6 md:w-8 md:h-8 rounded-full bg-[#043a9c] flex justify-center items-center">
                  <MdEmail className="text-white text-sm md:text-lg" />
                </div>
                <div className="text-white pl-3 text-[12px] md:text-[16px]">
                  <p>Email Us:</p>
                  <a href="mailto@info@3point3.co.za">info@3point3.co.za</a>
                </div>
              </div>
              <div className="w-full flex flex-row pt-3 text-[10px] md:text-[16px]">
                <div className="w-6 h-6 md:w-8 md:h-8 rounded-full bg-[#043a9c] flex justify-center items-center">
                  <MdLocationOn className="text-white text-sm md:text-lg" />
                </div>
                <div className="text-white pl-3">
                  <p>Address:</p>
                  <p>Centurion Pretoria</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
