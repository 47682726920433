import Header from "./Header";
import Main from "./Main";
import AboutUs from "./AboutUs";
import Services from "./Services";
import Testimonials from "./Testimonials";
import Alpine from "alpinejs";
import ContactUs from "./ContactUs";
import Footer from "./Footer";
import { Routes, Route } from "react-router-dom";
import { ScrollToTop } from "./ScrollToTop";

// initialize Alpine
Alpine.start();

function App() {
  return (
    <div className="App">
      <Header />
      <Main />
      <AboutUs />
      <Services />
      {/* <Testimonials /> */}
      <ContactUs />
      <Footer />

      <ScrollToTop />
      {/* <button
        id="to-top-button"
        onclick="goToTop()"
        title="Go To Top"
        className="fixed z-90 bottom-8 right-8 border-0 w-16 h-16 rounded-full drop-shadow-md
         bg-[#28256D] text-white text-3xl font-bold animate-bounce"
      >
        &uarr;
      </button> */}
    </div>
  );
}

export default App;
